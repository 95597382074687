<template>
  <div class="terms" v-if="page">
    <div class="outer-container">
      <div class="inner-container">
        <div class="terms-content">
          <h2>Copyright</h2>
          <p>In accordance with Australian Government policy, Wine Australia aims to make its
            information available on an open and reusable basis where possible, including on its websites and social
            media channels.<br> <br> Most material on Wine Australia’s websites will be protected by copyright held by
            Wine Australia or a supplier of information to Wine Australia. Your right to use this material may be
            permitted under:</p>
            <ul>
              <li>An open access licence:&nbsp;<br> For example:
                <ul>
                  <li>A Creative Commons (CC) Attribution 3.0 Australia licence. The
                    terms and conditions of the CC licences are available on the CC Australia website. Where a CC
                    licence applies, the material will be marked with a CC logo such as:
                    <ul>
                      <li>&nbsp;<img alt="" src="@/assets/by.png" style="width: 100px; height: 34px;"></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>A restricted licence: The terms of such a licence will be set out in
                association with the material covered by it.
              </li>
              <li>Wine Australia’ s default terms of use: Where no terms of use are
                associated with a set of material (for example, in a copyright notice on a publication), then you may
                download and use that material for personal non-commercial use but you may not otherwise copy or change
                the material or supply that material to any other person. You must not alter or remove any copyright
                statement or logo included on, or in connection with, the material.
              </li>
            </ul>
            <h4>Wine Australia social media channels</h4>
            <p>Wine Australia makes a number of social media channels (including Facebook pages, Twitter accounts,
              YouTube channels and blogs) available to the public. You may use all material posted by Wine Australia on
              these channels under a Creative Commons (CC) Attribution 3.0 Australia licence, except where otherwise
              noted. The terms and conditions of this licence is available on the CC Australia website. Material posted
              by any person other than Wine Australia is made available to you by that person under a Creative Commons
              (CC) Attribution 3.0 licence.</p> <h4>Attribution</h4>
            <p>Where you use material licensed to you by Wine Australia under a CC licence, you must attribute that use
              in the following way and include the web address where the material was obtained:</p>
            <p>Sourced from Wine Australia Corporation at [insert web address where material was obtained].</p> <h4>No
              circumvention of terms</h4>
            <p>You must check and comply with the licensing information for each set of material you wish to use.&nbsp;This
              may require you to contact Wine Australia or a third party copyright owner for permission to use the
              material. You may also use any material in accordance with rights you have under the Copyright Act 1968
              (for example under the fair dealing provisions or statutory licences).</p>
            <p>Use of material in a way not permitted by this copyright notice may be an infringement of copyright.
              Infringing copyright may expose you to legal action by, and liability to, the copyright owner.</p> <h4>
              Contact us</h4>
            <p>For inquiries regarding copyright including requests to use material in a way that is beyond the scope of
              the terms of use that apply to it, please email&nbsp;<a href="mailto:rachel.triggs@wineaustralia.com">rachel.triggs@wineaustralia.com</a>
            </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import Kontent from "@/kontent";

export default {
  name: 'Privacy',
  props: {},
  data() {
    return {
      page: null
    }
  },
  mounted() {
    Analytics.trackPage('Privacy')
    Kontent.getItemByType('privacy_page').then((res) => {
      this.page = res.data.items[0]

      setTimeout(function(){window.prerenderReady = true}, 1000)
    })
  }
}
</script>

<style lang="scss">
.terms-content {
  margin-top: 30px;
  margin-bottom: 50px;
  color: #FFF;

  table {
    border: none !important;
    border-top: 1px solid #eceae5 !important;
    border-left: 1px solid #eceae5 !important;
  }

  table td, table th {
    border: none !important;
    border-bottom: 1px solid #eceae5 !important;
    border-right: 1px solid #eceae5 !important;
    padding: 0 10px;
  }

  h1, h2, h3, h4, h5 {
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: bold;
  }

  p {
    margin: 15px 0;
  }

  a {
    text-decoration: underline;
  }

  ul {
    margin: 20px 0;
    padding-left: 25px;

    > li {
      list-style-type: disc;
    }
  }

  ol {
    margin: 20px 0;
    padding-left: 25px;

    > li {
      list-style-type: decimal;
    }
  }
}
</style>
